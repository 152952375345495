const links = [
  {
    label: 'Home',
    to: 'home',
    alt: 'Página inicial',
  },

  {
    label: 'Sobre mim',
    to: 'about',
    alt: 'Sobre mim',
  },

  {
    label: 'Contato',
    to: 'contact',
    alt: 'Contato',
  },
];

export default links;
